/**
 *
 * Manages and provides the global state and operations related to product data within the application,
 * specifically focusing on music products. This context uses a reducer for state management and handles asynchronous
 * actions to fetch and update data related to products and their filters from external APIs.
 *
 * Key functionalities include:
 * - Loading initial and additional product data through infinite scrolling mechanisms.
 * - Filtering products based on various attributes such as moods, lengths, tempos, and BPMs.
 * - Managing user interactions with product favorites, supporting add and remove operations.
 * - Searching within the listed products based on user input.
 *
 * The context ensures that all child components have access to product-related data and actions, enabling a cohesive
 * and responsive user experience across the product sections of the application. It handles loading states, error states,
 * and updating product listings based on user-selected filters.
 *
 * @returns {Object} - An object containing the context provider and the custom hook to access the context, which provides
 * the product state and operations as values for use in consumer components.
 * @module src/context/ProductContext
 */

import React, { useEffect, useReducer, useContext } from 'react';
import axios from 'axios';
import { useAuthContext } from './useAuthContext';

const productsEndPoint = `${process.env.REACT_APP_END_POINT}api/ecwid/products`;
const filtersEndPoint = `${process.env.REACT_APP_END_POINT}api/ecwid/filters`;
const addFavoriteEndPoint = `${process.env.REACT_APP_END_POINT}api/users/addfavorite`;
const removeFavoriteEndPoint = `${process.env.REACT_APP_END_POINT}api/users/removefavorite`;

const initialState = {
  //Song Data
  songs: [],
  numberOfSongs: 0,
  isLoadingProducts: true,
  hasMoreData: true,
  offset: 0,
  numberOfSongsNotLoaded: 20,
  //FilterButton Data
  isLoadingFilters: true,
  moodList: [],
  lengthList: [],
  tempoList: [],
  bpmList: [],
  //FilterButton Selection Data
  search: '',
  selectedMoods: [],
  selectedLengths: [],
  selectedTempos: [],
  selectedBpms: [],
};

const actionTypes = {
  //Song Data
  SET_SONGS_LOADING: 'SET_SONGS_LOADING',
  SET_SONGS_DATA: 'SET_SONGS_DATA',
  SET_SONGS_ERROR: 'SET_SONGS_ERROR',
  //FilterButton Data
  SET_FILTER_LOADING: 'SET_FILTER_LOADING',
  SET_FILTER_DATA: 'SET_FILTER_DATA',
  SET_FILTER_ERROR: 'SET_FILTER_ERROR',
  //FilterButton Selection Data
  SET_SEARCH: 'SET_SEARCH',
  SET_SELECTED_MOODS: 'SET_SELECTED_MOODS',
  SET_SELECTED_LENGTHS: 'SET_SELECTED_LENGTHS',
  SET_SELECTED_TEMPOS: 'SET_SELECTED_TEMPOS',
  SET_SELECTED_BPMS: 'SET_SELECTED_BPMS',
  //Favorites Button Data
  SET_FAVORITE_BOOLEAN: 'SET_FAVORITE_BOOLEAN',
};

const reducer = (state, action) => {
  // Add cases for each action type
  switch (action.type) {
    //Song Data
    case actionTypes.SET_SONGS_LOADING:
      return { ...state, isLoadingProducts: true, ...action.payload };
    case actionTypes.SET_SONGS_DATA:
      return { ...state, isLoadingProducts: false, ...action.payload };
    case actionTypes.SET_SONGS_ERROR:
      return { ...state, isLoadingProducts: false, hasMoreData: false };
    //FilterButton Data
    case actionTypes.SET_FILTER_LOADING:
      return { ...state, isLoadingFilters: true };
    case actionTypes.SET_FILTER_DATA:
      return { ...state, isLoadingFilters: false, ...action.payload };
    case actionTypes.SET_FILTER_ERROR:
      return { ...state, isLoadingFilters: false };
    //FilterButton Selection Data
    case actionTypes.SET_SEARCH:
      return { ...state, search: action.payload };
    case actionTypes.SET_SELECTED_MOODS:
      return { ...state, selectedMoods: [...action.payload] };
    case actionTypes.SET_SELECTED_LENGTHS:
      return { ...state, selectedLengths: [...action.payload] };
    case actionTypes.SET_SELECTED_TEMPOS:
      return { ...state, selectedTempos: [...action.payload] };
    case actionTypes.SET_SELECTED_BPMS:
      return { ...state, selectedBpms: [...action.payload] };
    //ProducCardFavorite
    case actionTypes.SET_FAVORITE_BOOLEAN:
      return { ...state, songs: [...action.payload] };
    /* istanbul ignore next - defensive coding */
    default:
      return state;
  }
};

const ProductContext = React.createContext();

export const ProductProvider = ({ children }) => {
  const {
    signOut,
    authToken,
    setAuthToken,
    isLoading: isLoadingAuthData,
  } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSongEndpointUrl = ({ newOffSet }) => {
    const productEndpointUrl = new URL(
      `${productsEndPoint}?offset=${newOffSet}`,
    );

    const appendSearchParams = (paramName, values) => {
      if (values.length === 0) {
        return;
      }
      if (Array.isArray(values)) {
        productEndpointUrl.searchParams.append(
          paramName,
          values.map(({ id }) => id).toString(),
        );
      } else {
        productEndpointUrl.searchParams.append(paramName, values);
      }
    };

    appendSearchParams('keyword', state.search);
    appendSearchParams('attribute_Length', state.selectedLengths);
    appendSearchParams('categories', state.selectedMoods);
    appendSearchParams('attribute_Tempo', state.selectedTempos);
    appendSearchParams('attribute_BPM', state.selectedBpms);

    return productEndpointUrl.href;
  };

  const GetFilterData = async () => {
    dispatch({ type: actionTypes.SET_FILTER_LOADING, payload: {} });
    axios
      .get(filtersEndPoint, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        if (response.data.token) {
          sessionStorage.setItem('jwt', response.data.token);
          setAuthToken(response.data.token);
        }
        dispatch({
          type: actionTypes.SET_FILTER_DATA,
          payload: {
            moodList: response?.data?.Mood,
            lengthList: response?.data?.Length,
            tempoList: response?.data?.Tempo,
            bpmList: response?.data?.BPM,
          },
        });
      })
      .catch(() => {
        dispatch({ type: actionTypes.SET_FILTER_ERROR, payload: {} });
      });
  };

  const GetSongData = async ({ newOffSet, newSongs }) => {
    // if we are getting brand new song data, then we should reset the current song data
    if (newOffSet === 0) {
      dispatch({
        type: actionTypes.SET_SONGS_LOADING,
        payload: {
          songs: [],
          numberOfSongs: 0,
          isLoadingProducts: true,
          hasMoreData: true,
          offset: 0,
          numberOfSongsNotLoaded: 0,
        },
      });
    } else {
      dispatch({ type: actionTypes.SET_SONGS_LOADING, payload: {} });
    }

    try {
      const response = await axios.get(getSongEndpointUrl({ newOffSet }), {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.data.token) {
        sessionStorage.setItem('jwt', response.data.token);
        setAuthToken(response.data.token);
      }

      const { count, items, limit, offset, total } = response?.data;

      dispatch({
        type: actionTypes.SET_SONGS_DATA,
        payload: {
          numberOfSongs: total,
          songs: [...newSongs, ...items],
          offset: offset + limit,
          hasMoreData: count + offset < total || false,
          numberOfSongsNotLoaded:
            total - (count + offset) > 0 ? total - (count + offset) : 0,
        },
      });
    } catch (err) {
      dispatch({ type: actionTypes.SET_SONGS_ERROR, payload: {} });
      // Perform actions for 401 Unauthorized error
      if (err?.response?.status === 401) {
        signOut();
      }
    }
  };

  const handleLoadMore = () => {
    // since we are loading more data from the current filtered selection, we will use the same data and offset
    GetSongData({ newOffSet: state.offset, newSongs: state.songs });
  };

  const setSearch = async (search) =>
    dispatch({ type: actionTypes.SET_SEARCH, payload: search });
  const setSelectedLengths = async (lengths) =>
    dispatch({ type: actionTypes.SET_SELECTED_LENGTHS, payload: lengths });
  const setSelectedBpms = async (bpms) =>
    dispatch({ type: actionTypes.SET_SELECTED_BPMS, payload: bpms });
  const setSelectedTempos = async (tempos) =>
    dispatch({ type: actionTypes.SET_SELECTED_TEMPOS, payload: tempos });
  const setSelectedMoods = async (moods) =>
    dispatch({ type: actionTypes.SET_SELECTED_MOODS, payload: moods });

  useEffect(() => {
    if (!isLoadingAuthData) {
      GetFilterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAuthData]);

  const toggleFavorite = async (item) => {
    try {
      if (!item.favorite) {
        axios
          .post(
            addFavoriteEndPoint,
            { songId: item.id },
            { headers: { Authorization: `Bearer ${authToken}` } },
          )
          .then((res) => {
            if (res.status === 200) {
              let newSongs = [...state.songs];
              newSongs.forEach((song) => {
                if (song.id === item.id) {
                  song.favorite = true;
                }
              });
              dispatch({
                type: actionTypes.SET_FAVORITE_BOOLEAN,
                payload: newSongs,
              });
            }
          });
      } else {
        axios
          .post(
            removeFavoriteEndPoint,
            { songId: item.id },
            { headers: { Authorization: `Bearer ${authToken}` } },
          )
          .then((res) => {
            if (res.status === 200) {
              let newSongs = [...state.songs];
              newSongs.forEach((song) => {
                if (song.id === item.id) {
                  song.favorite = false;
                }
              });
              dispatch({
                type: actionTypes.SET_FAVORITE_BOOLEAN,
                payload: newSongs,
              });
            }
          });
      }
    } catch (err) {
      console.error('Error adjusting favorite:', err.message);
    }
  };

  useEffect(() => {
    if (!isLoadingAuthData) {
      // Wrapped in timeout to debounce requests
      const getData = setTimeout(
        () => {
          // since the filterButton criteria has changed we need to reset the offset & songs
          GetSongData({ newOffSet: 0, newSongs: [] });
          // if there are no songs we should fetch the data immediately
        },
        state.songs.length === 0 ? 0 : 2500,
      );
      return () => clearTimeout(getData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingAuthData,
    state.search,
    state.selectedMoods,
    state.selectedLengths,
    state.selectedTempos,
    state.selectedBpms,
  ]);

  const context = {
    ...state,
    isLoading: state.isLoadingProducts || state.isLoadingFilters,
    GetSongData,
    setSearch,
    setSelectedLengths,
    setSelectedBpms,
    setSelectedTempos,
    setSelectedMoods,
    handleLoadMore,
    toggleFavorite,
  };

  return (
    <ProductContext.Provider value={context}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};
