/**
 * Main App .js
 * @module src/index
 */
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import { Color, text } from './tokens/color';

/**
 * Retrieves the app container from the DOM.
 * @type {HTMLElement}
 */
const container = document.getElementById('app');
/**
 * Creates a React root instance on the specified container.
 * @type {ReactDOM.Root}
 */

const root = createRoot(container);
/**
 * Configures and creates a theme using Material-UI settings.
 * @type {Mui.Theme}
 */
let theme = createTheme({
  palette: {
    primary: {
      main: Color.pink.standard,
    },
    secondary: {
      main: Color.purple.standard,
    },
    text: {
      primary: text.primary,
      secondary: text.secondary,
    },
  },
  typography: {
    fontFamily: '"Raleway", "Arial"',
    button: {
      textTransform: 'none',
      letterSpacing: '1.2px',
    },
  },
});
/**
 * Enhances the theme with responsive font sizes.
 * @type {Mui.Theme}
 */
theme = responsiveFontSizes(theme);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictMode>,
);
