import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/useAuthContext';

const RequireAuthAndSubscription = ({ children }) => {
  const navigate = useNavigate();
  const { isLoggedIn, isSubscribed, isLoading } = useAuthContext();

  useEffect(() => {
    if (!isLoading && !isSubscribed) {
      navigate('/subscribe');
    }

    if (!isLoading && !isLoggedIn) {
      navigate('/login');
    }
  }, [isLoading, isLoggedIn, isSubscribed, navigate]);

  return children;
};

export default RequireAuthAndSubscription;
