/**
 *
 * Provides a React context for authentication-related state and operations across the application.
 * This context manages user authentication states like logged-in status, subscription status, and error messages.
 * It uses a reducer to handle state transitions based on actions such as sign-in, sign-up, session checks, and sign-out.
 *
 * Functions within the context handle interactions with the backend authentication API, including:
 * - Signing in and signing up users.
 * - Checking session validity to maintain user state across sessions.
 * - Signing out users and cleaning up the session.
 * - Setting specific error messages based on API responses or network issues.
 *
 * The context is provided throughout the application via the `AuthProvider` component which wraps the application's component tree,
 * allowing any component to access authentication state and actions via the `useAuthContext` hook.
 *
 * @returns {React.Component} - The provider component that wraps the application and provides authentication-related state and functions to the component tree.
 * @module src/context/AuthContext
 */

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import axios from 'axios';

const userSignInEndPoint = `${process.env.REACT_APP_END_POINT}api/users/login`;
const userSignUpEndPoint = `${process.env.REACT_APP_END_POINT}api/users/register`;
const logOutEndPoint = `${process.env.REACT_APP_END_POINT}api/users/logout`;
const refreshEndPoint = `${process.env.REACT_APP_END_POINT}api/users/refresh`;

const initialValue = {
  isLoggedIn: false,
  email: '',
  isSubscribed: false,
  isLoading: true,
  errorMessage: {},
  authToken: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHECK_SESSION_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        isSubscribed: action.payload.isSubscribed,
        authToken: action.payload.authToken,
        isLoading: false,
      };
    case 'CHECK_SESSION_FAILURE':
      return {
        ...initialValue,
        isLoading: false,
      };
    case 'SIGN_IN':
      return {
        ...state,
        isLoading: true,
      };
    case 'SIGN_UP':
      return {
        ...state,
        isLoading: true,
      };
    case 'SIGN_IN_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        email: action.payload.email,
        isSubscribed: action.payload.isSubscribed,
        authToken: action.payload.authToken,
        isLoading: false,
      };
    case 'SIGN_UP_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        email: action.payload.email,
        isSubscribed: action.payload.isSubscribed,
        authToken: action.payload.authToken,
        isLoading: false,
      };
    case 'SIGN_OUT':
      return { ...initialValue, isLoading: false };
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isLoading: false,
      };
    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        authToken: action.payload.authToken,
      };
    /* istanbul ignore next - defensive coding */
    default:
      return state;
  }
};

const AuthContext = createContext();

export const AuthProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    ...initialState,
  });

  const signIn = (userData) => {
    dispatch({ type: 'SIGN_IN', payload: {} });

    axios
      .post(userSignInEndPoint, userData, {
        headers: { Authorization: `Bearer ${state.authToken}` },
      })
      .then((res) => {
        if (res.data.type !== undefined && res.data.type === 'error') {
          dispatch({
            type: 'SET_ERROR_MESSAGE',
            payload: { errorMessage: res.data.error },
          });
        } else {
          if (res.data.token) {
            sessionStorage.setItem('jwt', res.data.token);
          }

          dispatch({
            type: 'SIGN_IN_SUCCESS',
            payload: {
              email: userData.email,
              isSubscribed: res?.data?.isSubscribed,
              ...(res.data.token && { authToken: res.data.token }),
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR_MESSAGE',
          payload: { errorMessage: { form: 'Unexpected Network Error' } },
        });
      });
  };

  const checkSession = () => {
    const sessionKey = sessionStorage.getItem('jwt');
    if (sessionKey) {
      axios
        .post(
          refreshEndPoint,
          { info: 'sessionCheck' },
          { headers: { Authorization: `Bearer ${sessionKey}` } },
        )
        .then((res) => {
          if (res?.data?.success) {
            if (res.data.token) {
              sessionStorage.setItem('jwt', res.data.token);
            }

            dispatch({
              type: 'CHECK_SESSION_SUCCESS',
              payload: {
                isSubscribed: res?.data?.isSubscribed,
                ...(res.data.token && { authToken: res.data.token }),
              },
            });
          } else {
            dispatch({
              type: 'CHECK_SESSION_FAILURE',
              payload: {},
            });
          }
        })
        .catch(() => {
          dispatch({
            type: 'SET_ERROR_MESSAGE',
            payload: { errorMessage: { form: 'Unexpected Network Error' } },
          });
        });
    } else {
      dispatch({
        type: 'CHECK_SESSION_FAILURE',
        payload: {},
      });
    }
  };

  const signUp = (userData) => {
    dispatch({ type: 'SIGN_UP' });

    axios
      .post(userSignUpEndPoint, userData, {
        headers: { Authorization: `Bearer ${state.authToken}` },
      })
      .then((res) => {
        if (res.data.type !== undefined && res.data.type === 'error') {
          dispatch({
            type: 'SET_ERROR_MESSAGE',
            payload: { errorMessage: res.data.error },
          });
        } else {
          if (res.data.token) {
            sessionStorage.setItem('jwt', res.data.token);
          }

          dispatch({
            type: 'SIGN_UP_SUCCESS',
            payload: {
              email: userData.email,
              isSubscribed: res?.data?.isSubscribed,
              ...(res.data.token && { authToken: res.data.token }),
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR_MESSAGE',
          payload: { errorMessage: { form: 'Unexpected Network Error' } },
        });
      });
  };

  const signOut = () => {
    axios
      .post(
        logOutEndPoint,
        { info: 'logOut' },
        { headers: { Authorization: `Bearer ${state.authToken}` } },
      )
      .finally(() => {
        dispatch({ type: 'SIGN_OUT' });
        sessionStorage.removeItem('jwt');
      });
  };

  const setErrorMessage = (newError) => {
    dispatch({
      type: 'SET_ERROR_MESSAGE',
      payload: { errorMessage: { ...newError } },
    });
  };

  const setAuthToken = (authToken) => {
    dispatch({ type: 'SET_AUTH_TOKEN', payload: { authToken } });
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        checkSession,
        signUp,
        signOut,
        setErrorMessage,
        setAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
