/**
 *
 * Defines the routing configuration for the application using React Router. This configuration specifies the
 * routes and their corresponding components, effectively mapping URLs to the React components that should
 * render for each route.
 *
 * The routes are set up with lazy loading to optimize the initial load time by splitting the code at a
 * route level. Each route potentially wraps its components in higher-order components to handle concerns
 * like authentication and subscription checks where necessary.
 *
 * Detailed route setup:
 * - '/' for the HomePage without any restrictions.
 * - '/subscribe' for the Subscribe page accessible directly.
 * - '/login' for the Login page accessible directly.
 * - '/app' for the Main page, wrapped within RequireAuthAndSubscription and ProductProvider for fetching and providing product data.
 * - '/contactUs' for the ContactForum page accessible directly.
 * - '/video' for the VideoPlayer, similarly wrapped in RequireAuthAndSubscription and ProductProvider.
 * - '/terms' for the Terms page accessible directly.
 * - '/guarantee' for the Guarantee page accessible directly.
 *
 * This setup ensures that the application correctly handles user navigation and renders the appropriate content
 * based on the current URL, enhancing the application's scalability and maintainability.
 *
 * @type {Array} - An array of route objects detailing the path and the component tree that should render for each path.
 * @module src/configs/routesConfig
 */

import React, { lazy } from 'react';
import RequireAuthAndSubscription from '../components/RequireAuthAndSubscription/RequireAuthAndSubscription';
import { ProductProvider } from '../context/useProductContext';

const Login = lazy(() => import('../pages/login'));
const Subscribe = lazy(() => import('../pages/subscribe'));
const Main = lazy(() => import('../pages/main'));
const ContactForum = lazy(() => import('../pages/ContactForum'));
// const VideoPlayer = lazy(() => import('../pages/video'));
const HomePage = lazy(() => import('../pages/home'));
const Terms = lazy(() => import('../pages/terms'));
const Guarantee = lazy(() => import('../pages/guarantee'));

const routesConfig = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/subscribe',
    element: <Subscribe />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/app',
    element: (
      <RequireAuthAndSubscription>
        <ProductProvider>
          <Main />
        </ProductProvider>
      </RequireAuthAndSubscription>
    ),
  },
  {
    path: '/contactUs',
    element: <ContactForum />,
  },
  // {
  //   path: '/video',
  //   element: (
  //     <RequireAuthAndSubscription>
  //       <ProductProvider>
  //         <VideoPlayer />
  //       </ProductProvider>
  //     </RequireAuthAndSubscription>
  //   ),
  // },
  {
    path: '/home',
    element: <HomePage />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/guarantee',
    element: <Guarantee />,
  },
];

export default routesConfig;
