/**
 * Main App .js
 * @module src/App
 */

import React, { Suspense } from 'react';
import { AuthProvider } from './context/useAuthContext';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoadingSpinner from './components/loadingSpinner';
import routesConfig from './configs/routesConfig';

/**
 * Creates a browser router using the provided routes configuration.
 * @type {ReactDOM.BrowserRouter}
 */
const router = createBrowserRouter(routesConfig);
/**
 * The main application component.
 * Sets the document title and wraps the app in the necessary providers.
 * @returns {JSX.Element} The rendered application component.
 */
const App = () => {
  document.title = 'MusMeApp';
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </Suspense>
  );
};

export default App;
