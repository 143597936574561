/**
 * Defines color palettes with various shades and surface backgrounds.
 * Each color palette includes light, standard, dark shades and, in some cases, surface colors.
 * Compliance notes with WCAG AA standards are also provided.
 * @type {Object}
 * @module src/tokens/color
 */

export const Color = {
  // Cyan: only dark is WCAG AA compliant on white backgrounds.
  cyan: {
    light: '#AFE7E9',
    standard: '#68d2d6',
    dark: '#237D80',
    surface: '#ffffff',
  },

  // Orange: These colors are not WCAG AA compliant.
  orange: {
    light: '#FCC74A',
    standard: '#fbb30c',
    dark: '#C98E03',
  },

  // Pink: standard and dark are WCAG AA compliant on white backgrounds.
  pink: {
    light: '#e295df',
    standard: '#c037be',
    dark: '#8b08a8',
    surface: '#fff6ff',
  },

  // Purple: standard and dark are WCAG AA compliant on white backgrounds.
  purple: {
    light: '#9590E0',
    standard: '#554ccc',
    dark: '#342D9F',
    surface: '#f6f6ff',
  },

  white: {
    light: '#ffffff',
  },
};
/**
 * Defines text color tokens for primary and secondary text.
 * @type {Object}
 */
export const text = {
  primary: '#3E4C4D',
  secondary: '#3D3D3DC0',
};
/**
 * Defines shadow presets for UI components, categorized by size.
 * @type {Object}
 */
export const shadow = {
  large: '6px 6px 0px rgba(62,76,77,0.25)',
  medium: '3px 3px 0px rgba(62,76,77,0.25)',
  small: '2px 2px 0px rgba(62,76,77,0.25)',
};
